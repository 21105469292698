import Vue from "vue";
import { PURCHASE_PURCHASE_STATUSES, PURCHASE_CLAIM_STATUSES } from "@/assets/variables/";

const ShopMethods = {
    install: function (Vue) {
        Vue.prototype.$getCartProductGroups = function (carts = []) {
            return carts.reduce((groups, cart) => {
                const { product } = cart;
                const group = groups.find(({ product: { _id } }) => _id == product._id);
                if (group) group.carts.push(cart);
                else groups.push({ product, carts: [cart] });
                return groups;
            }, []);
        };

        Vue.prototype.$getCartMaps = function (carts = []) {
            return Vue.prototype.$getCartProductGroups(carts).reduce((shippingGroups, productGroup) => {
                const { shipping, deliveryMessage } = productGroup?.product || {};

                let group = shippingGroups.find(({ shipping: { code } }) => code == shipping.code);
                if (group) group.groups.push(productGroup);
                else shippingGroups.push({ shipping, deliveryMessage, groups: [productGroup] });
                return shippingGroups;
            }, []);
        };

        Vue.prototype.$getProductPrice = function (carts = []) {
            return carts.reduce((sum, { salePrice, discountPrice, amount }) => sum + (salePrice + discountPrice) * amount, 0);
        };

        Vue.prototype.$getDiscountPrice = function (carts = []) {
            return carts.reduce((sum, { discountPrice, amount }) => sum + discountPrice * amount, 0);
        };

        Vue.prototype.$getCouponPrice = function (carts = [], coupons = []) {
            return coupons.reduce((couponPrice, coupon) => {
                switch (coupon.type) {
                    // 상품 쿠폰
                    case "product": {
                        var sum = carts.reduce((sum, cart) => {
                            if (cart.product._id == coupon._product) {
                                return sum + cart.salePrice * cart.amount;
                            } else return sum;
                        }, 0);

                        if (coupon.unit == "원") couponPrice += coupon.amount;
                        else if (coupon.unit == "%") {
                            if (!coupon.maxPrice) couponPrice += Math.ceil((sum * coupon.amount) / 100);
                            else if (coupon.maxPrice <= sum) couponPrice += coupon.maxPrice;
                        }
                        break;
                    }

                    // 카테고리 쿠폰
                    case "category": {
                        var sum = carts.reduce((sum, cart) => {
                            if (cart.product._category == coupon._category) {
                                return sum + cart.salePrice * cart.amount;
                            }
                            return sum;
                        }, 0);

                        if (coupon.unit == "원") couponPrice += coupon.amount;
                        else if (coupon.unit == "%") {
                            if (!coupon.maxPrice) couponPrice += Math.ceil((sum * coupon.amount) / 100);
                            else if (coupon.maxPrice <= sum) couponPrice += coupon.maxPrice;
                        }
                        break;
                    }

                    // 주문 쿠폰
                    case "order": {
                        var sum = carts.reduce((sum, cart) => {
                            return sum + cart.salePrice * cart.amount;
                        }, 0);

                        if (coupon.unit == "원") couponPrice += coupon.amount;
                        else if (coupon.unit == "%") {
                            if (!coupon.maxPrice) couponPrice += Math.ceil((sum * coupon.amount) / 100);
                            else if (coupon.maxPrice <= sum) couponPrice += coupon.maxPrice;
                        }
                        break;
                    }

                    // 배송비 쿠폰
                    case "delivery": {
                        couponPrice += coupon.amount;
                        break;
                    }
                }
                return couponPrice;
            }, 0);
        };

        Vue.prototype.$getDeliveryPrice = function (carts = [], shippings = []) {
            var { deliveryPrice, orderPrice } = Vue.prototype.$getCartProductGroups(carts).reduce(
                ({ deliveryPrice, orderPrice }, map) => {
                    switch (map.product.shipping.code) {
                        case "product": {
                            deliveryPrice += map.product.shipping.price || 0;
                            break;
                        }
                        case "order": {
                            orderPrice += map.carts.reduce((productPrice, cart) => {
                                return productPrice + cart.salePrice * cart.amount;
                            }, 0);
                            break;
                        }
                    }
                    return { deliveryPrice, orderPrice };
                },
                { deliveryPrice: 0, orderPrice: 0 }
            );

            if (orderPrice) {
                const shipping = shippings.find((shipping) => shipping.code == "order");
                deliveryPrice += shipping?.range?.basePrice <= orderPrice ? shipping?.range?.highPrice || 0 : shipping?.range?.lowPrice || 0;
            }

            return deliveryPrice;
        };

        Vue.prototype.$getIslandPrice = function (carts = [], islands = [], postcode) {
            return Vue.prototype.$getCartProductGroups(carts).reduce((islandPrice, map) => {
                if (map.product.islandsEnabled && postcode) {
                    var island = islands.find((island) => island.areas.find((area) => area.postcode == postcode));
                    if (island) islandPrice += map.product.islands.find((item) => item.code == island.code).price;
                }
                return islandPrice;
            }, 0);
        };

        Vue.prototype.$getStatusText = function ({ purchaseStatus, claimStatus, orderStatusMessage, claimStatusMessage } = {}) {
            if (purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value) {
                return "구매확정";
            }

            switch (claimStatus) {
                case PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value:
                case PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value:
                case PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value:
                case PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value:
                case PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value:
                case PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value:
                case PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value:
                case PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value:
                case PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value: {
                    return claimStatusMessage;
                }
                case PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value:
                case PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value:
                case PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value: {
                    return (() => {
                        let text = "";
                        if (orderStatusMessage) text += orderStatusMessage;
                        if (claimStatusMessage) text += text ? ` / ${claimStatusMessage}` : claimStatusMessage;
                        return text;
                    })();
                }
            }
            return orderStatusMessage;
        };

        Vue.prototype.$decode__productOptionName = (name = "") => [...name.split(" / ")].map((item) => item.split(": ").map(decodeURIComponent).join(": ")).join(" / ");
    },
};

Vue.use(ShopMethods);
