<template>
    <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    metaInfo() {
        return Object.assign({}, this.$data.seo);
    },
    data: () => ({
        seo: {},
        timer: null,
        latest: Date.now(),
    }),
    computed: {
        ...mapState(["likes"]),
        refreshToken() {
            return this.$store.state.refreshToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return JSON.parse(atob(this.refreshToken?.split(".")[1] || "") || "{}");
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.getSetting();
        this.getIslands();
        this.getShippings();
        this.getCategories();
        if (!this.likes) this.getLikes();
    },
    watch: {
        accessToken() {
            if (!this.accessToken) {
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            } else {
                if (!this.likes) this.getLikes();
            }
        },
    },
    methods: {
        ...mapActions(["getLikes", "getIslands", "getShippings", "getCategories"]),

        init() {
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
        },
        reset() {
            this.latest = Date.now();
        },
        onTimer() {
            clearTimeout();
            this.timer = setTimeout(() => {
                if (24 * 60 * 60 * 1000 < Date.now() - this.latest || this.payload.exp * 1000 <= Date.now()) {
                    this.$store.dispatch("logout");
                }
                this.onTimer();
            }, 1000);
        },

        getSetting() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/setting");

                    this.seo = res.data.setting.seo;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
